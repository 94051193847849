import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import { flushSync } from 'react-dom'

import ButtonV2 from '~/components/ButtonV2'
import { inputStyles } from '~/features/task-builder/utils/build-styles'
import { toast } from '~/utils/toast'
import { validateEmail } from '~/utils/validate-email'

export type OrganizationAddFormValues = {
  name: string
  members: { email: string; role: 'ADMIN' | 'MANAGER' | 'MEMBER' }[]
}

export type OrganizationAddFormProps = {
  values: OrganizationAddFormValues
  setValues: React.Dispatch<React.SetStateAction<OrganizationAddFormValues>>
  onWipChange?: (isWip: boolean) => void
}

export default function OrganizationAddForm(props: OrganizationAddFormProps) {
  return (
    <>
      <div className="mb-4">
        <label className={cn(inputStyles.label, 'text-base! mb-2')} htmlFor="organization-name">
          Organization Name*
        </label>
        <input
          id="organization-name"
          value={props.values.name}
          onChange={(e) => props.setValues((prev) => ({ ...prev, name: e.target.value }))}
          className={cn(inputStyles.input, inputStyles.focusRing)}
        />
      </div>

      <InviteMemberRepeaterForm
        members={props.values.members}
        setMembers={(members) => props.setValues((prev) => ({ ...prev, members }))}
        onWipChange={props.onWipChange}
      />
    </>
  )
}

const memberMap = {
  MEMBER: 'Member',
  MANAGER: 'Manager',
  ADMIN: 'Admin',
}

export function InviteMemberRepeaterForm(props: {
  members: OrganizationAddFormValues['members']
  setMembers: (members: OrganizationAddFormValues['members']) => void
  onWipChange?: (isWip: boolean) => void
}) {
  const [isWip, setIsWip] = useState(false)
  const [newMember, setNewMember] = useState<(typeof props.members)[number]>({
    email: '',
    role: 'MEMBER',
  })

  useEffect(() => {
    setIsWip(newMember.email.trim().length > 0)
  }, [newMember])

  useEffect(() => {
    props.onWipChange?.(isWip)
  }, [isWip, props])

  const memberIsValid = validateEmail(newMember.email?.trim())

  const addMember = () => {
    if (!memberIsValid) return

    // if the email is already in the list, dont add it again
    if (props.members.some((m) => m.email?.trim() === newMember.email?.trim())) {
      toast.error('This email is already in the list')
      return
    }

    props.setMembers([...props.members, newMember])

    setNewMember({ email: '', role: 'MEMBER' })

    // focus the new email input
    flushSync(() => {
      setTimeout(() => {
        const emailFields = document.querySelectorAll<HTMLInputElement>('[data-email-field]')
        const lastEmailField = emailFields[emailFields.length - 1]
        lastEmailField.focus()
      }, 0)
    })
  }

  const removeMember = (index: number) => {
    props.setMembers(props.members.filter((_, i) => i !== index))
  }

  return (
    <div>
      <label htmlFor="invite-users" className={cn(inputStyles.label, 'text-base! mb-2')}>
        Invite users to the new organization:
      </label>

      <div id="invite-users" className="flex items-center gap-3">
        <fieldset className="flex-1">
          <label htmlFor="member-email" className={cn(inputStyles.label, 'mb-21 text-base!')}>
            Email
          </label>

          <input
            id="member-email"
            value={newMember.email}
            data-email-field
            onChange={(e) => setNewMember((prev) => ({ ...prev, email: e.target.value }))}
            // {...(!memberIsValid ? { 'aria-invalid': true } : {})}
            className={cn(inputStyles.input, inputStyles.focusRing, 'font-base!')}
            onKeyDown={(e) => {
              // on enter, add a new member
              if (e.key === 'Enter') addMember()
            }}
          />
        </fieldset>

        <fieldset className="flex-1 max-w-[140px]">
          <label htmlFor="member-role" className={cn(inputStyles.label, 'mb-1 text-base!')}>
            Role
          </label>
          <select
            id="member-role"
            value={newMember.role}
            onChange={(e) => setNewMember((prev) => ({ ...prev, role: e.target.value as any }))}
            className={cn(inputStyles.input, inputStyles.focusRing, 'select-icon')}
            onKeyDown={(e) => {
              // on enter, add a new member
              if (e.key === 'Enter') addMember()
            }}
          >
            <option value="MEMBER">Member</option>
            <option value="MANAGER">Manager</option>
            <option value="ADMIN">Admin</option>
          </select>
        </fieldset>

        <div className="self-end">
          <ButtonV2
            type="button"
            intent="secondary-outlined"
            onClick={addMember}
            aria-label="Add member"
            disabled={!memberIsValid}
          >
            Add
          </ButtonV2>
        </div>
      </div>

      <ul className="space-y-3 mt-4">
        {props.members.map((member, i) => (
          <li key={`${member.email}-${i}`} className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <p>
                {member.email} <span>({memberMap[member.role]})</span>
              </p>
            </div>
            <button
              type="button"
              className="h-5 w-5 text-xs text-[#646464] hover:text-red-500 flex items-center justify-center rounded-full border border-gray-300 hover:bg-red-50 hover:border-red-500"
              onClick={() => removeMember(i)}
              aria-label={`Remove ${member.email}`}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
